import { Directive } from '@angular/core';

@Directive({
  selector: 'webview'
})
export class WebviewDirective {

  constructor() { }

}
